import { Categories, Category, Product } from '@pizza-hut-us-development/client-core';
import checkAvailability, { AvailabilityItem } from '@/graphql/helpers/checkAvailability';
import { IsCyo, MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { DisplayableMenuItem, DisplayableProduct } from '@/domain/product/types';
import {
  extractFromOptions,
  extractYumPastaAdditionalOption,
  getDefaultOption,
  getMatchedSizes,
  shapeOneClickDefaults,
  wingsAdditionalOptionsFromCCData
} from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts/helpers/menuProductsData';
import { MELT, PIZZA, WING } from '@/domain/constants';

import { CCExclusivePizza, CCGetProductsNonPizzaCategoryTypes, CCGetProductsObjectType } from './types';
import { OccasionApi } from '@/localization/constants';
import { HiddenProducts } from '@/api/reduxToolkit/hideProducts';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';

export type TransformCCData = <T extends CCExclusivePizza | CCGetProductsNonPizzaCategoryTypes>(
  categoryId: T,
  data: Category,
  occasion: OccasionApi,
  storeTimeZone: string
) => CCGetProductsObjectType<T>;
// get raw cc > transform/filter avail > transform entire  >
export const checkAndFilterbyAvailability = (
  // This function runs through raw cc data and filters by availibility
  rawCCProductArr: Product[],
  occasion: OccasionApi,
  storeTimeZone: string
) => rawCCProductArr.reduce((rawAvailableCCProductArr: AvailabilityItem<Product>[], currentRawCCProduct) => {
  const rawCCAvailProduct = checkAvailability(currentRawCCProduct, occasion, storeTimeZone);
  // Returns product if available
  return rawCCAvailProduct.available ? [...rawAvailableCCProductArr, rawCCAvailProduct] : rawAvailableCCProductArr;
}, []);

export const getCCTransformFn = (categoryId: Categories, isYumEcomm: boolean) => {
  const optimizely = getOrInitializeOptimizely();
  const oneClickAddDipsEnabled = optimizely?.isFeatureEnabled('fr-web-3718-one_click_add_dips');

  switch (categoryId) {
    case Categories.PIZZA:
      return (ccAvailProduct: AvailabilityItem<Product>): MenuRecipe => ({
        id: ccAvailProduct.id,
        name: ccAvailProduct.name,
        price: ccAvailProduct.price,
        imageURL: ccAvailProduct.imageURL || null,
        exists: ccAvailProduct.exists,
        outOfStock: ccAvailProduct.outOfStock || false,
        productDescription: ccAvailProduct.description,
        isCYO: ccAvailProduct.name === 'Create Your Own' ? IsCyo.TRUE : IsCyo.FALSE,
        availability: ccAvailProduct.availability ?? [],
        imageAltText: 'Pizza Image',
        staticLink: '',
        hidden: ccAvailProduct.hidden || false,
        priority: ccAvailProduct.displayOrder
      });
    case Categories.WINGS:
      return (ccAvailProduct: AvailabilityItem<Product>): DisplayableProduct => ({
        available: ccAvailProduct.available,
        id: ccAvailProduct.id,
        name: ccAvailProduct.name,
        type: ccAvailProduct.type || '',
        description: ccAvailProduct.description || '',
        imageUrl: ccAvailProduct.imageURL || '',
        isOutOfStock: ccAvailProduct.outOfStock,
        sodiumWarning: ccAvailProduct.sodiumWarning,
        displayOrder: ccAvailProduct.displayOrder,
        priority: ccAvailProduct.displayOrder,
        sizes: getMatchedSizes(ccAvailProduct, categoryId),
        additionalOptions: wingsAdditionalOptionsFromCCData(ccAvailProduct),
        preSelectedAdditionalOption: getDefaultOption(ccAvailProduct.defaultSelectedOption)
      });
    case Categories.LINEUP:
      return (ccAvailProduct: AvailabilityItem<Product>): DisplayableMenuItem => {
        const lineupProduct: DisplayableMenuItem = {
          available: ccAvailProduct.available,
          id: ccAvailProduct.id,
          name: ccAvailProduct.name,
          type: ccAvailProduct.type || '',
          description: ccAvailProduct.description || '',
          imageUrl: ccAvailProduct.imageURL || '',
          isOutOfStock: ccAvailProduct.outOfStock,
          sodiumWarning: ccAvailProduct.sodiumWarning,
          displayOrder: ccAvailProduct.displayOrder,
          priority: 0
        };

        if (ccAvailProduct && ccAvailProduct.type === PIZZA) {
          lineupProduct.price = ccAvailProduct.price;
        } else if (ccAvailProduct.name?.toLowerCase().includes(WING)) {
          lineupProduct.sizes = getMatchedSizes(ccAvailProduct, Categories.WINGS);
          lineupProduct.additionalOptions = wingsAdditionalOptionsFromCCData(ccAvailProduct, true);
        } else {
          lineupProduct.sizes = getMatchedSizes(ccAvailProduct, categoryId);
          lineupProduct.additionalOptions = extractFromOptions(ccAvailProduct);
        }

        if (ccAvailProduct.type === MELT || ccAvailProduct.name?.toLowerCase().includes(MELT.toLowerCase())) {
          lineupProduct.oneClickDefaults = shapeOneClickDefaults(ccAvailProduct, isYumEcomm);
          lineupProduct.additionalOptions = {};
        }

        return lineupProduct;
      };
    // TODO: Remove this case: 'melts' once CC puts in their fix CCS-1729
    case 'melts' as Categories:
    case Categories.MELTS:
    case Categories.PARTYOFONE:
      return (ccAvailProduct: AvailabilityItem<Product>): DisplayableProduct => {
        const meltsOrPartyOfOneProduct: DisplayableMenuItem = {
          additionalOptions: extractFromOptions(ccAvailProduct),
          available: ccAvailProduct.available,
          description: ccAvailProduct.description || '',
          displayOrder: ccAvailProduct.displayOrder,
          id: ccAvailProduct.id,
          imageUrl: ccAvailProduct.imageURL || '',
          isOutOfStock: ccAvailProduct.outOfStock,
          name: ccAvailProduct.name,
          preSelectedAdditionalOption: getDefaultOption(ccAvailProduct.defaultSelectedOption),
          priority: 0,
          sizes: getMatchedSizes(ccAvailProduct, categoryId),
          sodiumWarning: ccAvailProduct.sodiumWarning,
          type: ccAvailProduct.type || ''
        };
        if (ccAvailProduct.type === MELT) {
          meltsOrPartyOfOneProduct.oneClickDefaults = shapeOneClickDefaults(ccAvailProduct, isYumEcomm);
        }
        return meltsOrPartyOfOneProduct;
      };
    case Categories.PASTA:
      return (ccAvailProduct: AvailabilityItem<Product>): DisplayableProduct => ({
        // TODO: fix hardcode WEB-2725 pasta for breadsticks modifier.
        // This is our temp yum solution for breadsticks and cheese sticks dd.
        additionalOptions:
          isYumEcomm
            ? extractYumPastaAdditionalOption(ccAvailProduct)
            : extractFromOptions(ccAvailProduct),
        available: ccAvailProduct.available,
        description: ccAvailProduct.description || '',
        displayOrder: ccAvailProduct.displayOrder,
        id: ccAvailProduct.id,
        imageUrl: ccAvailProduct.imageURL || '',
        isOutOfStock: ccAvailProduct.outOfStock,
        name: ccAvailProduct.name,
        preSelectedAdditionalOption: getDefaultOption(ccAvailProduct.defaultSelectedOption),
        priority: 0,
        sizes: getMatchedSizes(ccAvailProduct, categoryId),
        sodiumWarning: ccAvailProduct.sodiumWarning,
        type: ccAvailProduct.type || ''
      });
    default:
      return (ccAvailProduct: AvailabilityItem<Product>): DisplayableProduct => {
        const product: DisplayableProduct = {
          additionalOptions: extractFromOptions(ccAvailProduct),
          available: ccAvailProduct.available,
          description: ccAvailProduct.description || '',
          displayOrder: ccAvailProduct.displayOrder,
          id: ccAvailProduct.id,
          imageUrl: ccAvailProduct.imageURL || '',
          isOutOfStock: ccAvailProduct.outOfStock,
          name: ccAvailProduct.name,
          preSelectedAdditionalOption: getDefaultOption(ccAvailProduct.defaultSelectedOption),
          priority: 0,
          sizes: getMatchedSizes(ccAvailProduct, categoryId),
          sodiumWarning: ccAvailProduct.sodiumWarning,
          type: ccAvailProduct.type || ''
        };

        if (oneClickAddDipsEnabled && isYumEcomm && categoryId === 'dips') {
          product.oneClickDefaults = shapeOneClickDefaults(ccAvailProduct);
        }

        return product;
      };
  }
};

export const transformProductCategoryData = <T extends Categories>(
  categoryId: Categories,
  data: Category,
  occasion: OccasionApi,
  storeTimeZone: string,
  improvedProductFilteringEnabled: boolean,
  isYumEcomm: boolean,
  hiddenProducts?: HiddenProducts
): CCGetProductsObjectType<T> | undefined => {
  const productTransformer = getCCTransformFn(categoryId, isYumEcomm);

  // When we remove this FF, we should just append the .map to the end of the return and eliminate this fn
  const removeNonPricedProducts = (products: DisplayableProduct[]) => {
    if (improvedProductFilteringEnabled) {
      return products
        .map((product) => {
          // For YumEcomm we were asked to not apply price filter logic for dips
          if (isYumEcomm && categoryId === 'dips') return product;
          if (product.price) return product;

          // Only return sizes that have a price
          const sizesWithPrice = product.sizes?.filter((size) => !!size.price);
          if (sizesWithPrice && sizesWithPrice.length > 0) return { ...product, sizes: sizesWithPrice };

          return null;
        })
        .filter((product) => product !== null);
    }
    // For YumEcomm we were asked to not apply price filter logic for dips
    return products.filter(
      (product) => product.price || !!(product.sizes ?? [])[0]?.price || (isYumEcomm && categoryId === 'dips')
    );
  };

  switch (categoryId) {
    case Categories.PIZZA:
      return {
        menu: {
          items: checkAndFilterbyAvailability(data.products ?? [], occasion, storeTimeZone)
            .map(productTransformer as (ccAvailProduct: AvailabilityItem<Product>) => MenuRecipe)
            .filter(
              (pizza) => (!!pizza.exists || isYumEcomm)
                && !hiddenProducts?.products?.some((hiddenProduct) => pizza.id.includes(hiddenProduct))
            )
        }
      } as CCGetProductsObjectType<T>;
    default:
      return {
        productsForMainCategory: removeNonPricedProducts(
          checkAndFilterbyAvailability(data.products ?? [], occasion, storeTimeZone).map(
            productTransformer as (ccAvailProduct: AvailabilityItem<Product>) => DisplayableProduct
          )
        ).filter(
          (product) => product && !hiddenProducts?.products?.some((hiddenProduct) => hiddenProduct.includes(product.id))
        )
      } as CCGetProductsObjectType<T>;
  }
};
