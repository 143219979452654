import {
  Categories,
  useGetProductsByCategoryQuery,
  DiningOccasion
} from '@pizza-hut-us-development/client-core';
import {
  useEffect,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { OccasionApi } from '@/localization/constants';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { selectOccasion } from '@/localization/selectors';
import { transformProductCategoryData } from './transformProductCategoryData';
import { RootState } from '@/rootStateTypes';
import { FinalizedCCTransforms } from './types';
import { useTemporaryCCContext } from '../temporaryCCContext';
import { LocalizedMenuPageSections } from '@/common/components/LocalizedProductTile/LocalizedProductTile.types';
import { useRefetchOnYumEcommChanged } from '@/clientCore/helper/useRefetchOnYumEcommChanged';
import { useGetProductsToHideQuery } from '@/api/reduxToolkit/hideProducts';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';

declare type ProductCategoryResponse = {
  data: FinalizedCCTransforms | null | undefined;
  loading: boolean | null;
  error: unknown | null;
  storeID: string | null | undefined;
};

declare type Props = { categoryId: Categories; skip?: boolean };

declare type UseCCGetProducts = (arg0: Props) => ProductCategoryResponse;

export const isLocalizedMenuPageSection = (data: FinalizedCCTransforms | null | undefined): data is LocalizedMenuPageSections => {
  if (!data) return false;
  return (data as LocalizedMenuPageSections).productsForMainCategory !== undefined;
};

export const useCCGetProducts: UseCCGetProducts = ({ categoryId, skip }) => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const cmsEnvId = useSelector((state: RootState) => state.domain.cmsEnv.id);
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const [storeNumber, setStoreNumber] = useState<string | undefined>(undefined);
  const [storeTimezone, setStoreTimeZone] = useState<string | undefined>(undefined);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const { isOptimizelyApiLoading } = useTemporaryCCContext();
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const [{ enabled: improvedProductFilteringEnabled }] = useDecision('fr-web2468-improved-product-price-filtering');

  const shouldSkipQuery = skip || categoryId === Categories.DEALS || categoryId === Categories.PZONE || !storeNumber || (isOptimizelyApiLoading && !isYumEcomm);

  useEffect(() => {
    if (storeDetails?.storeNumber) setStoreNumber(storeDetails.storeNumber);
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const {
    currentData,
    isLoading,
    isFetching,
    error,
    refetch,
    isUninitialized
  } = useGetProductsByCategoryQuery(
    {
      categoryId,
      environmentId: cmsEnvId,
      storeNumber,
      occasion: DiningOccasion[occasion as keyof typeof DiningOccasion],
      includeNutrition: true
    },
    {
      skip: shouldSkipQuery,
      refetchOnMountOrArgChange: true // TODO: Remove when authenticated users are supported when isYumEcomm = true
    }
  );
  // TODO: Remove when authenticated users are supported when isYumEcomm = true
  useRefetchOnYumEcommChanged({ refetch, isUninitialized });

  const { data: hiddenProducts } = useGetProductsToHideQuery();

  const isQueryLoading = isLoading || isFetching;

  if (shouldSkipQuery || isQueryLoading) {
    return {
      data: null,
      loading: !skip && isQueryLoading,
      error: null,
      storeID: null
    };
  }

  const data = currentData && storeTimezone
    ? transformProductCategoryData(
      categoryId,
      currentData,
      occasion,
      storeTimezone,
      improvedProductFilteringEnabled,
      isYumEcomm,
      hiddenProducts
    )
    : undefined;

  if (debugEnabled) {
    if (data) {
      transformationDebugLogging('useCCGetProducts', `useGetProductsByCategoryQuery Category: ${categoryId}`, currentData, data, debugMode, [
        DebugModeOptions.MENU,
        DebugModeOptions.TRANSFORMATIONS
      ]);
    }
  }

  return {
    data,
    loading: isQueryLoading,
    error,
    storeID: storeDetails?.storeNumber
  };
};
